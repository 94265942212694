import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import 'intl'
import { FaEnvelope, FaPhone } from 'react-icons/fa'
import { formatPhoneNumber } from '../utils/formatPhoneNumber'
function PartDetails() {

  const part = useSelector(s => s.selectedPart)

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const [image, setImage] = useState(part.data.images[0])


  return (
    <div style={{ minHeight: '60vh' }}>
      <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
        <h1 style={{ fontWeight: 'bold', }}>Part Details</h1>
        <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
      </div>
      <div style={{ margin: '30px' }}>
        <Container>
          <Row>
            <Col md={7}>
              <div style={{marginBottom:20, textAlign:'center'}}>
                <img src={image} alt="" width={'auto'} height={400} />
              </div>
              <Row>
                {
                  part.data.images.map(img =>
                    <Col sm={2} >
                      <img src={img} alt="" width={'100%'} onClick={()=>setImage(img)}/>
                    </Col>
                  )
                }

              </Row>
            </Col>
            <Col md={5}>
              <div className='shadow' style={{ padding: 10, borderRadius: 5 }}>
                <h5><b>Part Name</b></h5>
                <p>{part.data.partName}</p>
                <h5><b>Category</b></h5>
                <p>{part.data.category}</p>

                <h5><b>Part Description</b></h5>
                <p>{part.data.description}</p>

                <h5><b>Cost</b></h5>
                <p>{formatter.format(part.data.price)}</p>
                <h5><b>Location</b></h5>
                <p>{part.data.town}, {part.data.parish}</p>

                <h5><b>Sold By:</b></h5>
                <p>{part.data.soldBy}</p>




              </div>
              <div style={{marginTop:20}}>
                <Button href={`tel:${part.data.phone}`} style={{width:'100%', backgroundColor:'black', borderColor:'black', fontWeight:'bold'}}><FaPhone size={20} color='white'/>{' '}{formatPhoneNumber(part.data.phone)}</Button>
              </div>
              <div style={{marginTop:20}}>
                <Button href={`mailto:${part.data.email}`} style={{width:'100%', backgroundColor:'black', borderColor:'black', fontWeight:'bold'}}><FaEnvelope size={20} color='white'/>{' '}{part.data.email}</Button>
              </div>
            </Col>
          </Row>
        </Container>


      </div>

    </div>
  )
}

export default PartDetails
