import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Colors } from "../commons/Colors";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";

const Footer = ({ height }) => {

    return (<div style={{ backgroundColor: 'black', minHeight:200, padding:'50px 0' }}>
        <Container>
            
            <div style={{textAlign:'center'}}>
                <h3 style={{color:'white', textAlign:'center'}}>Contact Any-part ja</h3>
                <h1 style={{color:'white', fontWeight:'bolder'}}>info@anypartja.com</h1>
                <div style={{marginTop:20}}>
                    <FaFacebook size={35} color={'white'} style={{ marginBottom: 5, marginRight:30 }} />
                    <FaInstagram size={35} color={'white'} style={{ marginBottom: 5, marginRight:30 }} />
                    <FaTiktok size={35} color={'white'} style={{marginBottom: 5}} />
                </div>
            </div>
                <div  style={{ paddingTop: 20 }}>
                <div style={{textAlign:'right'}}>
                        <a href="/terms" style={{color:'white', marginRight:15}}>Terms &amp; Conditions</a>
                        <a href="/privacy-policy" style={{color:'white'}} >Privacy Policy</a>
                    </div>
                    <div style={{ color: '#fff', textAlign: 'left', fontWeight: '600' }}>
                        &copy; {new Date().getFullYear()} | All Rights Reserved By Any-Part Jamaica
                    </div>
                    
                </div>
           
        </Container> 
        </div>
    )

}

export default Footer;