import { useState } from "react";
import { connect } from "react-redux"
import { Button, Col, Row } from "reactstrap";
import { setSelectedPart } from "../store/actions"
import { PhoneOutlined} from '@ant-design/icons'
import { Colors } from "../commons/Colors";
import { ShopOutlined } from '@ant-design/icons'



const ViewPart = (props) => {
    
    const [index, setIndex] = useState(0)

    return (
        <div style={{padding:30}}>
            <Row>
                <Col md={{ size: 8, offset: 2 }}>
                    <Row>
                        <Col md={6}>
                            <h2>{props.part.data.partName}</h2>
                            <p >{props.part.data.description}</p>
                            <div><strong>Sold By: </strong> {props.part.data().soldBy}</div>
                            <div><ShopOutlined size={30}/>{` ${props.part.data.town}, ${props.part.data.parish}`}</div>
                            <Button style={styles.button}><PhoneOutlined /> {props.part.data.phone }</Button>
                        </Col>
                        <Col md={6}>
                            <img src={props.part.data.images[index]} alt="" width={'100%'} />
                        </Col>

                        <Col md={12}>
                            <Row>
                                <Col md={1} sm={3}></Col>
                                {props.part.data.images.map((img, i) =>
                                    <Col md={1} sm={3} key={i} onClick={()=>setIndex(i)} style={{cursor:'pointer'}}>
                                        <img src={img} alt="" width='100%' />
                                    </Col>
                                )
                                }
                                <Col md={1} sm={3}></Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
const styles = {
    button: {
        width: '100%',
        marginTop: 30,
        fontWeight: '700',
        backgroundColor: Colors.safron,
        color: Colors.xiketic,
        borderColor: Colors.safron,
    }
}
const mapStateToProps = (state) => {
    return { part: state.selectedPart }
}

export default connect(mapStateToProps, {})(ViewPart)