import { act } from "react-dom/test-utils";
import { createRoutesFromChildren } from "react-router-dom";
import { combineReducers } from "redux";

const setAuthenticatedReducer = (auth = false, action) => {
    if (action.type === 'SET_AUTHENTICATED') {
        return action.payload
    }
    return auth;
}

const setUserReducer = (user = null, action) => {
    if (action.type === 'SET_USER') {
        return action.payload
    }
    return user

}

const getPartsReducer = (parts = [], action) => {
    if (action.type === 'GET_PARTS') {
        return action.payload
    }
    return parts
}

const partsLoadedReducer = (loaded = false, action) => {
    if (action.type === 'IS_PARTS_LOADED') {
        return action.payload
    }

    return loaded
}

const setSelectedPartReducer = (part = null, action) => {
    if (action.type === 'SET_SELECTED_PART') {
        return action.payload
    }
    return part;
}


const getMyPartsReducer = (part = [], action) => {
    if (action.type === 'GET_MY_PARTS') {
        return action.payload
    }
    return part;

}
const getCategoryPartsReducer = (parts = null, action) => {
    if (action.type === 'GET_CATEGORYPART_PARTS') {
        return action.payload
    }
    return parts;
}


const selectedCategoryPartsReducer = (cat = [], action) => {
    if (action.type === 'SELECTED_CATEGORY_PARTS') {
        return action.payload
    }
    return cat
}

const selectedCategoryReducer = (cat = null, action) => {
    if (action.type === 'SELECTED_CATEGORY') {
        return action.payload
    }

    return cat
}


const setSignUpOpenReducer = (open = false, action) => {

    if (action.type === 'SET_SIGN_UP') {
        return action.payload
    }

    return open
}

const setSignInOpenReducer = (open = false, action) => {

    if (action.type === 'SET_SIGN_IN') {
        return action.payload
    }
    return open
}

export const getUserReducer = (user = null, action) => {

    if (action.type === 'GET_USER') {
        return action.payload
    }

    return user

}

const setOpenReducer = (open=false, action) =>{
    if(action.type === 'SET_OPEN'){
        return action.payload
    }
    return open
}



export default combineReducers({
    isAuthenticated: setAuthenticatedReducer,
    user: getUserReducer,
    parts: getPartsReducer,
    partsLoaded: partsLoadedReducer,
    selectedPart: setSelectedPartReducer,
    myParts: getMyPartsReducer,
    categoryParts: getCategoryPartsReducer,
    selectedCatParts: selectedCategoryPartsReducer,
    selectedCategory: selectedCategoryReducer,
    signinOpen: setSignInOpenReducer,
    signupOpen: setSignUpOpenReducer,
    open: setOpenReducer,
})