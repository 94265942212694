import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import { setJoinUsShow, setIsAuth} from '../redux/actions'
import { setAuthenticated, setSignInOpen, setSignUpOpen, getUser } from '../store/actions'
import logo from '../assets/anypart.png'
import { auth } from '../utils/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'


const SignInModal = () => {

    const dispatch = useDispatch()

    const show = useSelector(state => state.signinOpen)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [message, setMessage] = useState('')


    const handleClose = () => {
        dispatch(setSignInOpen(false))
    }


    const handleJoinUs = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (res) => {
                handleClose()
                dispatch(setAuthenticated(true))
                dispatch(getUser(res.user.uid))
            })
            .catch(err => {
                setMessage('That email/password incorrect!')
            })
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}

            >
                <div style={{ height: 'auto', margin: 20, overflow: 'scroll' }}>
                    <Modal.Title style={{ textTransform: 'uppercase' }}>Signin</Modal.Title>
                    <div style={{ textAlign: 'center' }}>
                        <img src={logo} style={{ width: '60%', marginBottom: 40 }} />
                    </div>

                    <p style={{ color: 'red' }}>{message}</p>



                    <Form.Label htmlFor="inputEmailAddress">Email</Form.Label>
                    <Form.Control
                        type="text"
                        id="inputEmailAddress"
                        onChange={(value) => { setEmail(value.target.value); setMessage('') }}
                    />
                    <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                    <Form.Control
                        type="password"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        onChange={(value) => { setPassword(value.target.value); setMessage('') }}
                    />

                    <p style={{marginTop:20, marginBottom:20,}}>Don't have an account? <span style={{color:'blue', cursor:'pointer'}} onClick={()=>{dispatch(setSignInOpen(false)); dispatch(setSignUpOpen(true))}}>Create an Account</span></p>


                    <div style={{ marginTop: 20 }}>
                        <Button style={{ width: '45%', marginRight: '2.5%', backgroundColor: 'red', borderColor: 'red' }} onClick={handleClose}>CANCEL</Button>
                        <Button style={{ width: '45%', marginLeft: '2.5%', backgroundColor: 'black', borderColor: 'black' }} variant="success" onClick={handleJoinUs}>SIGNIN</Button>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default SignInModal
