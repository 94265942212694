import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setSelectedPart, selectedCategoryParts, selectedCategory } from '../store/actions';
import PartCard from '../components/PartCard';
import { categories } from '../commons/categories';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Home = ({ parts, partsLoaded, setSelectedPart, selected, selectedCategoryParts, selectedCategory }) => {



  
const countCat = (cat) =>{
  let count = 0;

  parts.forEach(part => {

    if(part.data.category === cat)
    count+= 1;
  });

  return count;
} 


  if (!partsLoaded) {
    return (<div>Loading</div>)
  } else {


    return (
      <>
        <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
          <h3>Welcome to</h3>
          <h1 style={{ fontWeight: 'bold', }}>Any-Part Jamaica</h1>
          <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
        </div>
        <Container>
          <Row style={{ margin: '30px 0' }}>
            {
              categories.map(cat =>
                <Col md={3}>
                 <a href='/category'>
                    <div onClick={() => { selectedCategoryParts(parts, cat.value); selectedCategory(cat)}}
                      style={{ ...styles.catCard, backgroundImage: `url(${cat.image})`, }}>
                      <div style={{ height: 200, padding: '90px 0', backgroundColor: 'rgba(0,0,0,.6)', position: 'relative' }}>
                        <h3 style={{ textTransform: 'uppercase', textAlign: 'center', fontWeight: 'bold', color: 'white', stroke: 'red' }}>{cat.name}</h3>
                        <div style={{ position: 'absolute', bottom: 10, left: 10 }}>
                          <h6 style={{ fontWeight: 'bold', color: 'white' }}>{countCat(cat.value)} Listings</h6>
                        </div>
                      </div>
                    </div>
                    </a>
                </Col>
              )
            }

          </Row>
        </Container>
      </>
    )
  }
}

const styles = {


  catCard: {
    height: 200,
    border: '1px solid silver',
    borderRadius: 10,
    marginBottom: 20,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    cursor: 'pointer'
  },

  card: {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: 200,
    marginBottom: 20,
    cursor: 'pointer'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,.7)',
  },
  heading: {
    color: '#fff',
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center'
  },
  price: {
    color: '#fff',
    fontSize: 20,
    fontWeight: '600',
    position: 'absolute',
    bottom: 10,
  },
  category: {
    color: '#fff',
    margin: 0,
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: '700',
    textAlign: 'center',
  }
}


const mapStateToProps = (state) => {
  return { parts: state.parts, partsLoaded: state.partsLoaded, selected: state.selectedPart }
}
export default connect(mapStateToProps, { setSelectedPart, selectedCategoryParts, selectedCategory })(Home);
