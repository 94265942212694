import React, { useState } from 'react'
import { FaSearch, FaShoppingBag } from "react-icons/fa"
import { FiShoppingBag, FiGlobe, FiHeart } from "react-icons/fi"
import logo from '../assets/anypart.png';
// import {
//     NavbarBrand,
//     Navbar,
//     NavbarToggler,
//     Collapse,
//     Nav,
//     NavItem,
//     NavLink,
//     UncontrolledDropdown,
//     DropdownToggle,
//     DropdownItem,
//     DropdownMenu,
//     Container,

// } from 'reactstrap';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Colors } from '../commons/Colors';
import { connect, useSelector } from 'react-redux';
import firebase from '../utils/firebase';
import { setAuthenticated, setUser, setSignUpOpen, setSignInOpen } from '../store/actions';
import { setSelectedPart, selectedCategoryParts, selectedCategory } from '../store/actions';
import { categories } from '../commons/categories';
import { useNavigate } from 'react-router-dom';
import JoinUsModal from './JoinUsModal';
import SignoutModal from './SignoutModal';
import SignInModal from './SignInModal';
import { useDispatch } from 'react-redux';
import { setOpen } from '../store/actions';

const Header = ({ height, user, setAuthenticated, setUser, selectedCategory }) => {

    let navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false);
    

    const [joinUs, setJoinUs] = useState(false)
    const [signout, setSignout] = useState(false)
    const [signin, setSignin] = useState(false)

    const isAuthenticated = useSelector(s => s.isAuthenticated)

    const dispatch = useDispatch()

    return (
        <div style={{ minHeight: height, borderColor: 'black', width: '100%', borderStyle: 'solid', borderBottomWidth: 2, position: 'fixed', zIndex: 99, backgroundColor: 'white' }} >
            <JoinUsModal show={joinUs} close={() => setJoinUs(false)} />
            <SignoutModal show={signout} close={() => setSignout(false)} />
            <SignInModal show={signin} close={() => setSignin(false)} />
            <Navbar expand="lg" className="justify-content-end" style={{ backgroundColor: '#000', flex: 1, padding: '0 40px', height: 40 }}>
                <Navbar id="basic-navbar-nav" className="justify-content-end" style={{ color: '#fff' }}>
                    <Nav className="justify-content-end">
                        {/* <Nav.Link href='/help' className='nav-link1'>Help</Nav.Link>
                        <Nav.Item style={{ marginTop: 5, marginRight: 10, marginLeft: 10 }}>|</Nav.Item> */}
                        {isAuthenticated ?
                            <>
                                <Nav.Link className='nav-link1' onClick={() => setSignout(true)}>SignOut</Nav.Link>
                                <Nav.Item style={{ marginTop: 5, marginRight: 10, marginLeft: 10 }}>|</Nav.Item>
                                <Nav.Link href='/account' className='nav-link1'>Account</Nav.Link>

                            </>

                            : <>
                                <Nav.Link className='nav-link1' onClick={() => dispatch(setSignUpOpen(true))}>Sign Up</Nav.Link>
                                <Nav.Item style={{ marginTop: 5, marginRight: 10, marginLeft: 10 }}>|</Nav.Item>
                                <Nav.Link className='nav-link1' onClick={() => dispatch(setSignInOpen(true))}>Sign In</Nav.Link>
                            </>
                        }

                        {/* <Nav.Item style={styles.navButton} >
                            <Nav.Link
                                href={isAuthenticated ? "/post-part" : "/login"}
                                style={{ color: '#000', textAlign: 'center'}}>
                                SELL A PART
                            </Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                </Navbar>
            </Navbar>
            <Container style={{ borderColor: 'black', borderBottomWidth: 2 }}>

                <Navbar
                    className="container-fluid"
                    // color="light"
                    expand="md"
                    dark
                    sticky='true'

                >
                    <Navbar.Brand href="/">
                        <img src={logo} style={{ width: 300, }} alt='Logo' />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: '#000' }} />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center" style={{ marginLeft: '27vw' }}>
                        <Nav className="justify-content-center">
                            <Nav.Link href="/" className='Link'>Home</Nav.Link>
                            <NavDropdown title="Categories" id="basic-nav-dropdown" >
                                {categories.map(cat =>
                                    <NavDropdown.Item href="/category" onClick={() => { selectedCategory(cat) }} style={{ fontWeight: 'bold' }}>
                                        {cat.name}
                                    </NavDropdown.Item>
                                )}


                            </NavDropdown>
                            <Nav.Link href="/terms" className='Link'>Terms</Nav.Link>

                            {/* <Nav.Link href="/contact" className='Link'>Contact</Nav.Link> */}

                            <div style={{ marginLeft: 30, borderRadius: 30, border: '1px solid silver', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', flexDirection: 'row', height: 35 }} onClick={()=>dispatch(setOpen(true))}>
                                <FaSearch size={20} color={'gray'} style={{ marginLeft: 10, marginRight: 5, marginTop: 7 }} />
                                <input placeholder='Search' autoComplete={false} style={{ border: 'none', height: 35, color: 'gray' }} onClick={()=>dispatch(setOpen(true))} onFocus={()=>dispatch(setOpen(true))}/>
                            </div>

                            {/* <Nav.Link href="/privacy" className='Link'>
                                <FiHeart size={20} color={'gray'} style={{ marginBottom: 5 }} />
                            </Nav.Link> */}

                            <Nav.Link  className='Link' onClick={() => {
                                if (!isAuthenticated) {
                                    dispatch(setSignInOpen(true))
                                }else{
                                    navigate('post-part')
                                }
                            }}>
                                <div style={{ color: 'white', backgroundColor: 'black', padding: '0 10px', borderRadius: 30, paddingTop: 5, marginTop: -7, fontSize:18, width:150, textAlign:'center' }}>Sell&nbsp;a&nbsp;Part</div>
                            </Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </div>
    )
}

let styles = {
    navLink: {
        fontWeight: '800',
        color: '#000',

    },
    navButton: {
        backgroundColor: Colors.safron,
        fontWeight: 'bolder',
        borderRadius: 20,
        marginLeft: 20,

    }
}

const mapStateToProps = (state) => {
    return { isAuthenticated: state.isAuthenticated, user: state.user }
}

export default connect(mapStateToProps, { setAuthenticated, setUser, selectedCategoryParts, selectedCategory, })(Header);