import { firestore } from '../utils/firebase'
import { collection, getDocs, query, where, onSnapshot, getDoc } from 'firebase/firestore';

export const setAuthenticated = (bool) => {
    return {
        type: 'SET_AUTHENTICATED',
        payload: bool,
    }
}

export const getCategoryParts = (category) => async dispatch => {
    await firestore.collection('parts').where('category', '==', category).onSnapshot((res) => {

        var data = []

        res.forEach(doc => {
            data.push({ id: doc.id, data: doc.data() })
        })
        dispatch({
            type: 'GET_CATEGORYPART_PARTS',
            payload: data
        })
    })
}

export const setUser = (user) => {
    return {
        type: 'SET_USER',
        payload: user
    }
}

export const setPartsLoaded = (bool) => {
    return {
        type: 'IS_PARTS_LOADED',
        payload: bool
    }
}

export const setSelectedPart = (part) => {
    return {
        type: 'SET_SELECTED_PART',
        payload: part
    }
}


export const getMyParts = (uid) => async dispatch => {

    const coll = collection(firestore, 'parts')
    const q = query(coll, where('userId', '==', uid))
    const userSnap = await getDocs(q)
    var data = []

    userSnap.docs.forEach(doc => {
        data.push({ id: doc.id, data: doc.data() })
    })

    dispatch({
        type: 'GET_MY_PARTS',
        payload: data
    })

}

export const getParts = () => async dispatch => {
    await firestore.collection('parts').orderBy("dateAdded", 'desc').onSnapshot(res => {

        var data = []
        res.forEach(doc => {
            data.push({ id: doc.id, data: doc.data() })
        })

        dispatch(setPartsLoaded(true))


        dispatch({
            type: 'GET_PARTS',
            payload: data
        })
    })


}


export const selectedCategoryParts = (parts, cat) => async dispatch => {

    let data = []

    parts.map(part => {
        if (part.data.category === cat) {
            data.push(part)
        }

    })

    dispatch({
        type: 'SELECTED_CATEGORY_PARTS',
        payload: data
    })

}


export const selectedCategory = (cat) => dispatch => {
    dispatch({
        type: 'SELECTED_CATEGORY',
        payload: cat
    })
}

export const setSignUpOpen = (open) => dispatch => {
    dispatch({
        type: 'SET_SIGN_UP',
        payload: open
    })
}

export const setSignInOpen = (open) => dispatch => {
    dispatch({
        type: 'SET_SIGN_IN',
        payload: open
    })
}

export const getUser = (uid) => async dispatch => {
    const coll = collection(firestore, 'users')
    const q = query(coll, where('userId', '==', uid))

    const userSnap = await getDocs(q)

    const data = { id: userSnap.docs[0].id, data: userSnap.docs[0].data() }

    dispatch({
        type: 'GET_USER',
        payload: data
    })

}


export const setOpen = (bool) =>{
    return {
        type:'SET_OPEN',
        payload: bool
    }
}