import { Input } from "antd";
import { useState } from "react";
import firebase from "../utils/firebase";
import { connect } from "react-redux";
import { setAuthenticated, setUser } from "../store/actions";
import { Button, Row, Col } from "reactstrap";
import { Colors } from "../commons/Colors";
import { useNavigate } from "react-router-dom";
import background from '../assets/loginBackground.jpg'
import partsBackground from '../assets/tom-conway.jpg'

const Login = (props) => {

    const footerHeight = 60;
const headerHeight = 80;
const height = window.innerHeight;
const viewHeight = height - footerHeight - headerHeight;

    let navigate = useNavigate();
    const [phone, setPhone] = useState('')


    // 'recaptcha-container' is the ID of an element in the DOM.


    const setUpRecaptcha = () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                handleSignIn();
            },
        }, firebase.app());
    }


    const handleSignIn = () => {

        setUpRecaptcha();
        const appVerifier = window.recaptchaVerifier;

        firebase.auth().signInWithPhoneNumber(`+1${phone}`, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                console.log('Message sent');


                const code = window.prompt('Enter Code');
                confirmationResult.confirm(code).then((result) => {
                    // User signed in successfully.
                    // const user = result.user;
                    props.setAuthenticated(true)
                    props.setUser(firebase.auth().currentUser)

                    if (firebase.auth().currentUser.displayName) {
                        navigate('/')
                    } else {
                        navigate('/my-profile')
                    }


                    // ...
                }).catch((error) => {
                    // User couldn't sign in (bad verification code?)
                    // ...
                });
                // ...
            }).catch((error) => {
                // Error; SMS not sent
                console.log('Error', error);
                // ...
            });
        // window.recaptchaVerifier.clear()
    }

    return (
        <><div style={{background:`url(${partsBackground})`, backgroundPosition:'center', backgroundSize:'cover', minHeight: viewHeight}}>

       
            <Row>
                <Col md={{ size: 6, offset: 3 }} style={{ padding:40, height:window.screen.availHeight*.6}}>


                    <div style={{ 
                        borderColor: Colors.oliveGreen, 
                        borderWidth: 1, 
                        borderRadius: 10, 
                        borderStyle:'solid', 
                        marginLeft:50,
                        marginRight:50,
                        padding:30,
                        height:window.screen.availHeight*.5,
                        backgroundImage:`url(${background})`
                        }}>
                        <Row>
                            <h2 className="text-center" style={{textTransform:'uppercase', fontWeight:'700', fontSize:40, color:'#fff', marginBottom:30}}>Login</h2>
                            
                            <Col md={{ size: 10, offset: 1 }}>
                                <label style={{color:'#fff', marginBottom:10, fontSize:20}}>Phone Number <sup>*</sup></label>
                                <Input addonBefore="+1" maxLength={10} showCount value={phone}
                                    placeholder="8765551111"
                                    containerStyle={{borderRadius:20}}
                                    onChange={(e) => setPhone(e.target.value)} />
                                    <div style={{marginTop:20, color:'#fff', font:12}}>Don't Have an Account?<span style={{color:Colors.safron}}>  Sign Up Now</span></div>
                                <Col md={{ size: 8, offset: 2 }}>
                                    <Button style={{ ...styles.button, backgroundColor: phone.length < 10 ? 'silver' : Colors.safron }} onClick={handleSignIn}
                                        disabled={phone.length < 10}>
                                        Login
                                    </Button>
                                </Col>
                            </Col>



                            <div id='recaptcha-container'></div>
                        </Row>
                    </div>






                    <div id='recaptcha-container'></div>

                </Col>
            </Row>
            </div>
        </>
    )

}

const styles = {
    button: {
        width: '100%',
        marginTop: 30,
        fontWeight: '700',
        color: Colors.xiketic,
        borderRadius:25,
        border:'none'
    }
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, { setAuthenticated, setUser })(Login);