import React from 'react'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { setOpen, setSelectedPart } from '../store/actions'
import 'intl'
import { FaTimes } from "react-icons/fa"



const Search = () => {
    const open = useSelector(state => state.open)
    const parts = useSelector(state => state.parts)
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    if (open) {
        return (
            <div style={{ width: '100vw', height: '100%', zIndex: 200, backgroundColor: 'rgba(100,100,100, 0.85)', position: 'fixed', padding: 40 }}>
                <div style={{ width: '100%', height: '100%', backgroundColor: 'white', borderRadius: 20, padding: 20, position:'relative' }}>
                    <div style={{position:'absolute', right:20, cursor:'pointer'}} onClick={()=>dispatch(setOpen(false))}>
                        <FaTimes color='red' size={30}/>
                    </div>
                    <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Search for a part</h1>
                    <input placeholder='Search' autoComplete={false} style={{ border: '1px black solid', borderRadius: 20, padding: '5px 10px', height: 35, color: 'black', width: 300 }}  onChange={e=>setSearch(e.target.value)}/>
                    <Row style={{ marginTop: 20 }}>
                        {parts.map(part => {
                            if (part.data.partName.toLowerCase().includes(search.toLowerCase())) {
                                return (
                                    <Col md={3} style={{ marginBottom: 25 }}>
                                        <a href='/part-details' onClick={() => { dispatch(setSelectedPart(part)); dispatch(setOpen(false)) }} style={{ textDecoration: 'none', color: 'black' }}>
                                            <div className='shadow' style={{ width: '100%', height: 300, borderRadius: 10, backgroundImage: `url(${part.data.images[0]})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>

                                            </div>
                                            <h4 style={{ color: 'black', fontWeight: 'bold', marginTop: 10 }}>{part.data.partName}</h4>
                                            <p style={{ textTransform: 'capitalize', margin: 0 }}>{part.data.town}, {part.data.parish}</p>
                                            <p>Sold By: <b>{part.data.soldBy}</b></p>
                                            <p style={{ fontWeight: 'bold', fontSize: '1.3em', marginTop: -20 }}>{formatter.format(part.data.price)}</p>
                                        </a>
                                    </Col>
                                )
                            }
                        }
                        )
                        }
                    </Row>
                </div>

            </div>
        )
    } else {
        return null
    }

}

export default Search
