import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Terms() {
  return (
    <div style={{ minHeight: '60vh' }}>
      <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
        <h1 style={{ fontWeight: 'bold', }}>Terms of Use</h1>
        <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
      </div>
      <Container>
        <Row>
          <Col md={12} style={{ margin: '30px 0' }}>
            <p>
              Welcome to <b>AnyPart JA</b> (hereafter called the "Platform"). By accessing and using this Platform, you agree to comply with the terms and conditions outlined herein. If you do not agree with these terms, please refrain from using the Platform.
            </p>

            <h4><b>User Responsibilities</b></h4>
            <ul>
              <li>
                You are responsible for the accuracy and completeness of the information you provide on the Platform.
              </li>
              <li>
                Users must comply with all applicable laws and regulations.
              </li>
              <li>
                All interactions and transactions on the Platform are the sole responsibility of the users involved.
              </li>
            </ul>

            <h4><b>Registration and User Accounts</b></h4>
            <ul>
              <li>
                Users must create an account to access certain features of the Platform.
              </li>
              <li>
                Users are responsible for maintaining the security of their login credentials.
              </li>
              <li>
                The Platform reserves the right to terminate or suspend user accounts at its discretion.
              </li>
            </ul>

            <h4><b>Listing and Selling</b></h4>
            <ul>
              <li>
                Sellers are responsible for creating accurate and detailed listings of parts.
              </li>
              <li>
                Sellers are obligated to fulfill orders promptly and provide accurate information about the listed parts.
              </li>
            </ul>
            <h4><b>Buying</b></h4>
            <ul>
              <li>
                Buyers are responsible for verifying the condition of car parts before making a purchase.
              </li>
              <li>
                The purchasing process on the Platform involves: making contact with the seller of the desired part. Once communication is established, the buyer and seller will determine method to facilitate payment, shipping and all other concerns.
              </li>
            </ul>

            <h4><b>Payment and Fees</b></h4>
            <ul>
              <li>
                Sellers hold the right to accept payment through whatever method they see fit.
              </li>
              <li>
                Sellers may be subject to fees or commissions associated with listing periods and other premium services
              </li>
            </ul>

            <h4><b>Dispute Resolution</b></h4>
            <ul>
              <li>
                Users agree to attempt to resolve disputes between buyers and sellers in good faith.
              </li>
              <li>
                The Platform may, at its discretion, assist in dispute resolution but is not obligated to do so.
              </li>
            </ul>
            <h4><b>Limitation of Liability</b></h4>
            <ul>
              <li>
                The Platform makes no warranties or guarantees regarding the quality, legality, or fitness for a particular purpose of any part listed.
              </li>
              <li>
                The Platform shall not be liable for any damages, losses, or expenses incurred by users.
              </li>
              <li>
                The Platform, its Owners, and Associates do not act on behalf of the Seller, his/her agents, or associates
              </li>
            </ul>

            <h4><b>Indemnification</b></h4>
            <ul>
              <li>
                Users agree to indemnify and hold the Platform harmless from any claims, losses, or damages arising from their use of the service.
              </li>
            </ul>

            <h4><b>Modification of Terms</b></h4>
            <ul>
              <li>
                The Platform reserves the right to modify these terms and conditions at any time.
              </li>
              <li>
                Users will be notified of any changes, and continued use of the Platform constitutes acceptance of the modified terms.
              </li>
            </ul>

            <h4><b>Privacy Policy</b></h4>
            <ul>
              <li>
                Please see the <a href='/privacy-policy'>
                  Privacy Policy
                </a>
              </li>
            </ul>

            <h4><b>Governing Law</b></h4>
            <ul>
              <li>
                These terms are governed by the laws of Jamaica.
              </li>
              <li>
                Any disputes arising under or in connection with these terms shall be resolved in the courts of Jamaica.
              </li>
            </ul>


          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Terms
