
import './App.css';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import { connect } from 'react-redux';
import { getParts } from './store/actions';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/Login';
import { useEffect } from 'react';
import PostPart from './pages/PostPart';
import MyParts from './pages/MyParts';
import ViewPart from './pages/ViewPart';
import Profile from './pages/Profile';
import Category from './pages/Category';
import background from './assets/gerneral-background.jpeg'
import 'bootstrap/dist/css/bootstrap.min.css';
import Help from './pages/Help';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import PartDetails from './pages/PartDetails';
import Privacy from './pages/Privacy';
import Account from './pages/Account';
import Search from './components/Search';

const hist = createBrowserHistory()
const footerHeight = 60;
const headerHeight = 80;
const height = window.innerHeight;
const viewHeight = height - footerHeight - headerHeight;

function App(props) {

  useEffect(() => {
    props.getParts();
  }, [])

  return (
    <BrowserRouter history={hist}>
      <Header height={headerHeight} />
      <Search />
      <div style={{ minHeight: viewHeight, backgroundSize: 'cover', paddingTop:110 }}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/view-part' element={<ViewPart />} />
            <Route path='my-profile' element={<Profile />} />
            <Route path='/post-part' element={ <PostPart {...props} />}/>
            <Route path='/my-parts' element={<MyParts {...props} />} />
          <Route path='/part-details' element={<PartDetails/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/terms' element={<Terms/>} />
            <Route path='/help' element={<Help/>} />
          <Route path='/login' element={<Login {...props} />} />
          <Route path='/category' element={<Category />} />
          <Route path='/privacy-policy' element={<Privacy/>} />
          <Route path='/account' element={<Account/>} />
          <Route path="*" element={<Home replace />} />
        </Routes>
      </div>
      <Footer height={footerHeight} />
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return { isAuthenticated: state.isAuthenticated }
}
export default connect(mapStateToProps, { getParts })(App);
