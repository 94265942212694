import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import { setJoinUsShow, setIsAuth} from '../redux/actions'
import { setAuthenticated, setSignUpOpen, getUser } from '../store/actions'
import logo from '../assets/anypart.png'
import Colors from '../commons/Colors';
import { firestore, auth } from '../utils/firebase'
import { formatPhoneNumber } from '../utils/formatPhoneNumber'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { doc, addDoc, collection } from 'firebase/firestore'


const JoinUsModal = () => {

    const dispatch = useDispatch()
   
    const show = useSelector(state => state.signupOpen)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [firstName, setFirstName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [cPassword, setCPassword] = useState('')

    const [message, setMessage] = useState('')

    const handleClose = () => {
        dispatch(setSignUpOpen(false))
    }

    const handleJoinUs = () => {

        if (password === cPassword) {
           
            createUserWithEmailAndPassword(auth, email, password)
                .then(() => {
                    alert('here')
                    signInWithEmailAndPassword(auth, email, password)
                        .then(async (res) => {
                            dispatch(getUser(res.user.uid))
                            
                            await addDoc(collection(firestore, 'users'), {
                                email: email,
                                username: firstName,
                                company: companyName,
                                phone: phone,
                                profilePicture: '',
                                userId: res.user.uid
                            }).then(() => {
                                handleClose()
                                dispatch(setAuthenticated(true))
                            })

                        })

                })
                .catch(err => {
                    if (err.code === 'auth/email-already-in-use') {
                        setMessage('That email address is already in use!')

                    }

                    if (err.code === 'auth/invalid-email') {
                        setMessage('That email address is invalid!')
                    }

                    if (err.code === 'auth/weak-password') {
                        setMessage('Password is weak, enter a strong password!')
                    }
                })
        } else {
            setMessage("Passwords didn't match")
        }
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}

            >
                <div style={{ height: 'auto', margin: 20, overflow: 'scroll' }}>
                    <Modal.Title style={{ textTransform: 'uppercase' }}>Signup</Modal.Title>
                    <div style={{ textAlign: 'center' }}>
                        <img src={logo} style={{ width: '60%', marginBottom: 40 }} />
                    </div>

                    <p style={{ color: 'red' }}>{message}</p>

                    <Form.Label htmlFor="firstName">Username</Form.Label>
                    <Form.Control
                        type="text"
                        id="firstName"
                        onChange={(value) => { setFirstName(value.target.value); setMessage('') }}
                    />

                    <Form.Label htmlFor="lastName">Company Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="lastName"
                        onChange={(value) => { setCompanyName(value.target.value); setMessage('') }}
                    />
                    <Form.Label htmlFor="phoneNumber">Phone</Form.Label>
                    <Form.Control
                        type="text"
                        id="phoneNumber"
                        value={formatPhoneNumber(phone)}
                        onChange={(value) => { setPhone(value.target.value); setMessage('') }}
                    />

                    <Form.Label htmlFor="inputEmailAddress">Email</Form.Label>
                    <Form.Control
                        type="text"
                        id="inputEmailAddress"
                        onChange={(value) => { setEmail(value.target.value); setMessage('') }}
                    />
                    <Form.Label htmlFor="inputPassword5">Password</Form.Label>
                    <Form.Control
                        type="password"
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        onChange={(value) => { setPassword(value.target.value); setMessage('') }}
                    />

                    <Form.Label htmlFor="confirmPassword">Confirm Password</Form.Label>
                    <Form.Control
                        type="password"
                        id="confirmPassword"
                        aria-describedby="passwordHelpBlock"
                        onChange={(value) => { setCPassword(value.target.value); setMessage('') }}
                    />
                    <Form.Text id="passwordHelpBlock" muted style={{ marginBottom: 20 }}>
                        Your password must be 8-20 characters long, contain letters and numbers,
                        and must not contain spaces, special characters, or emoji.
                    </Form.Text>
                    <div style={{ marginTop: 20 }}>
                        <Button style={{ width: '45%', marginRight: '2.5%', backgroundColor: 'red', borderColor: 'red' }} onClick={handleClose}>CANCEL</Button>
                        <Button style={{ width: '45%', marginLeft: '2.5%', backgroundColor: email === '' || firstName==='' || companyName === '' || phone === '' || password ===''?'silver':'black', borderColor: 'black' }} 
                        disabled={email === '' || firstName==='' || companyName === '' || phone === '' || password ===''} onClick={handleJoinUs}>JOIN US</Button>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default JoinUsModal
