import ac from '../assets/categories/ac.jpeg'
import washer from '../assets/categories/washer.jpeg'
import auto from '../assets/categories/auto.jpeg'
import bicycle from '../assets/categories/bicycle.jpeg'
import bike from '../assets/categories/bike.jpeg'
import phone from '../assets/categories/phone.jpeg'
import computer from '../assets/categories/computer.jpeg'
import dryer from '../assets/categories/dryers.jpeg'
import electronics from '../assets/categories/electronics.jpeg'
import gardening from '../assets/categories/gardening.jpeg'
import industrial from '../assets/categories/industrial.jpeg'
import mechanical from '../assets/categories/mechanical.jpeg'
import radio from '../assets/categories/fan.jpeg'
import tv from '../assets/categories/tv.jpeg'
import stove from '../assets/categories/stove.jpeg'
import fridge from '../assets/categories/fridge.jpeg'

export const categories = [
    {
        name:'Air Conditioner Parts',
        value:'ac',
        image:ac

    },
    {
        name: 'Auto Parts',
        value: 'auto',
        image: auto
    },
    {
        name:'Bicycle Parts',
        value:'bicycle',
        image:bicycle
    },
    {
        name:'Bike Parts',
        value:'bike',
        image:bike
    },
    {
        name: 'Cellphone Parts',
        value: 'cellphone',
        image:phone
    },
    {
        name: 'Computer Parts',
        value: 'computer',
        image:computer
    },
    {
        name:'Dryer Parts',
        value:'dryer',
        image:dryer
    },
    {
        name: 'Electronic Parts',
        value: 'electronic',
        image:electronics
    },
    {
        name:'Fan Parts',
        value:'fan',
        image:radio
    },
    {
        name:'Gardening Tool Parts',
        value:'gardening',
        image:gardening
    },

    
    {
        name:'Refrigerator Parts',
        value:'fridge',
        image:fridge
    },
    {
        name:'Stove Parts',
        value:'stove',
        image:stove
    },
    {
        name:'Television Parts',
        value:'tv',
        image:tv
    },
    {
        name: 'Trucks/Tractors Parts',
        value: 'mechanical',
        image:mechanical
    },
    {
        name:'Washing Machine Parts',
        value:'washer',
        image:washer
    },
    {
        name: 'Other Parts',
        value: 'other',
        image:industrial
    },


]