import React from 'react'

function Contact() {
  return (
    <div style={{minHeight:'60vh'}}>
       <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
        <h1 style={{ fontWeight: 'bold', }}>Contact</h1>
        <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
      </div>
    </div>
  )
}

export default Contact
