import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, Col, Row, Modal } from "reactstrap"
import { getMyParts } from '../store/actions'
import { Colors } from '../commons/Colors';
import { Container } from "react-bootstrap"
import 'intl'
import { FaTrash } from "react-icons/fa";
import { collection, deleteDoc, doc } from "firebase/firestore";

import { } from 'react-bootstrap'
import logo from '../assets/anypart.png'
import { firestore } from "../utils/firebase";

const MyParts = (props) => {



    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
  

    const handleDelete = async (id) => {
        await deleteDoc(doc(firestore, 'parts', id)).then(() =>
            props.getMyParts(props.user.data.userId)
        )
    }



    useEffect(() => {
        props.getMyParts(props.user.data.userId)

    }, [])

    if (props.myParts.length === 0) {
        return (
        
        <div>
          <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
            <h1 style={{ fontWeight: 'bold', }}>My Parts</h1>
            <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
          </div>
    <Container>
    <div style={{textAlign:'center'}}>
            <h1 style={{textAlign:'center', marginTop:100, fontWeight:'bold', color:'blue'}}>NO Parts Available</h1>
            <Button href='/post-part' style={{ backgroundColor: 'black', borderColor: 'black', fontWeight: 'bold', textTransform: 'uppercase', width: '50%' }}>Sell a Part</Button>

          </div>
    </Container>
          
        </div>
        
        )
      }


    return (
        <div style={{}}>

            <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
                <h1 style={{ fontWeight: 'bold', }}>My Parts</h1>
                <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
            </div>

            <Container>
                <Row style={{ margin: '30px 0' }}>
                    {
                        props.myParts.map(part =>
                            <Col md={3} key={part.id}>
                                <div className='shadow' style={{ width: '100%', height: 300, borderRadius: 10, backgroundImage: `url(${part.data.images[0]})`, backgroundSize: 'cover', backgroundPosition: 'center center', position: 'relative' }}>

                                    <Button style={{ position: 'absolute', right: 10, bottom: 10, cursor: 'pointer', backgroundColor: 'transparent', borderColor: 'transparent' }} onClick={() => { handleDelete(part.id); alert('here') }}><FaTrash size={20} color='red' style={{}} /></Button>
                                </div>
                                <h4 style={{ color: 'black', fontWeight: 'bold', marginTop: 10 }}>{part.data.partName}</h4>
                                <p style={{ textTransform: 'capitalize', margin: 0 }}>{part.data.town}, {part.data.parish}</p>
                                <p>Sold By: <b>{part.data.soldBy}</b></p>
                                <p style={{ fontWeight: 'bold', fontSize: '1.3em', marginTop: -20 }}>{formatter.format(part.data.price)}</p>
                            </Col>
                        )
                    }


                </Row>

            </Container>

        </div>
    )

}


const styles = {
    button: {
        width: '100%',
        marginTop: 30,
        fontWeight: '700',
        backgroundColor: Colors.safron,
        color: Colors.xiketic,
        borderColor: Colors.safron,
    }
}

const mapStateToProps = (state) => {
    return { myParts: state.myParts, user: state.user }
}

export default connect(mapStateToProps, { getMyParts })(MyParts);