import React from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { FaPhone, FaEnvelope } from 'react-icons/fa'
import avavtar from '../assets/avatar.jpeg'
import { formatPhoneNumber } from '../utils/formatPhoneNumber'
const Account = () => {

    const user = useSelector(state => state.user)
    return (
        <div style={{ minHeight: '60vh' }}>
            <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
                <h1 style={{ fontWeight: 'bold', }}>My Account</h1>
                <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
            </div>
            <Container>
                <Row>
                    <Col md={12} style={{ margin: '30px 0' }}>
                        <div style={{}}>
                            <Row>
                                <Col md={4} >
                                    <div className='shadow' style={{ textAlign: 'center', borderRadius:5, overflow:'hidden' }}>
                                        <img src={user.data.profilePicture===''? avavtar:user.data.profilePicture} alt="profile picture" style={{ width: '100%', height: 'auto' }} />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className='shadow' style={{ borderRadius: 5, padding: 15 }}>
                                        <h5><b>Company Name</b></h5>
                                        <p>{user.data.company}</p>


                                        <h5><b>UserName</b></h5>
                                        <p>{user.data.username}</p>

                                        <Row>
                                            <Col md={6}>
                                                <p><FaPhone size={25} color='black' />{'  '} <b style={{ textTransform: 'uppercase' }}>{formatPhoneNumber(user.data.phone)}</b></p>
                                            </Col>
                                            <Col md={6}>
                                                <p><FaEnvelope size={25} color='black' />{'  '} <b style={{ textTransform: 'uppercase' }}>{user.data.email}</b></p>
                                            </Col>
                                        </Row>

                                        <Button href='my-parts' style={{ backgroundColor: 'black', borderColor: 'black', fontWeight: 'bold', textTransform: 'uppercase', width: '100%' }}>View My Parts</Button>

                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Account
