import { useState } from "react"
import { connect, useSelector } from "react-redux"
import { Row, Col,  Button } from "reactstrap"
import { Input} from "antd"
import { Colors } from "../commons/Colors"
import firebase from "../utils/firebase"
 
import { setAuthenticated, setUser } from "../store/actions";
import { useNavigate } from 'react-router-dom';

const Profile = (props) => {

    const [displayName, setName] = useState()
    const [email, setEmail] = useState(firebase.auth().currentUser?.email)
    const [image, setImage] = useState(null)
    const user = useSelector(state=>state.user)

    let navigate = useNavigate();

    const updateProfile = async ()=>{
        await firebase.auth().currentUser.updateProfile({
            displayName: displayName,
            email: email,  
        })

        await firebase.auth().currentUser.updateEmail(email).then(()=>{
        console.log('Current User', firebase.auth().currentUser)
        props.setUser(firebase.auth().currentUser)
        }
        )
    }

  
    return (
        <Row style={{padding:30}}>
            <Col md={{ size: 8, offset: 2 }}>
                <h2 className="text-center">{user.displayName === null?'Update Profile': 'My Profile'}</h2>

                <Row>
                    <Col md={12}>
                        <label>Display Name<sup>*</sup></label>
                        <Input value={user.displayName} onChange={e => setName(e.target.value)} />
                    </Col>

                    <Col md={12}>
                        <label>Email Address<sup>*</sup></label>
                        <Input value={user.email} onChange={e => setEmail(e.target.value)} />
                    </Col>
                    <Col md={{ size: 8, offset: 2 }}>
                           <Button
                                style={styles.button}
                                onClick={updateProfile}
                                disabled={
                                    displayName === null ||
                                    email === null
                                }
                            >
                                Update Profile
                            </Button>

                            <Button
                                style={styles.button}
                                onClick={()=>navigate('/post-part')}
                                disabled={
                                    user.displayName === null ||
                                    user.email === null
                                }
                            >
                                SELL A PART
                            </Button>
                        </Col>
                </Row>
            </Col>
        </Row>
    )
}
const styles = {
    button: {
        width: '100%',
        marginTop: 15,
        fontWeight: '700',
        backgroundColor: Colors.safron,
        color: Colors.xiketic,
        borderColor: Colors.safron,
        borderRadius:25
    }
}

const mapStateToProps = (state) => {
    return { user: state.user }
}

export default connect(mapStateToProps, {setUser})(Profile)