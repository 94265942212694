import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

const  firebaseConfig = {
  apiKey: "AIzaSyBXYMAEuB3N4MJGT75HJH9bz5PLYxRJCYY",
  authDomain: "any-part.firebaseapp.com",
  projectId: "any-part",
  storageBucket: "any-part.appspot.com",
  messagingSenderId: "594690427571",
  appId: "1:594690427571:web:1b56aa9b068f0bc09c7ec6",
  measurementId: "G-MH01LTD6BP"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  //Import Stroage
  // const auth = firebase.auth();

  const storage = firebase.storage();
  const firestore = firebase.firestore();
  const auth = firebase.auth();
  export {storage, firestore, auth, firebase as default};