export const Colors = {
    primary: '#00adee',
    green:'#31cb00',
    xiketic: '#141115',
    oliveGreen: '#657153',
    caribbeanGreen: '#03CEA4',
    safron: '#EAC435',
    bdazzledBlue:'#345995',
    terraCotta:'#ED6A5A'

}