import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Modal, Button } from 'react-bootstrap';
import { setAuthenticated } from '../store/actions'
import { signOut } from 'firebase/auth';
import { auth } from '../utils/firebase'
import logo from '../assets/anypart.png'



const SignoutModal = ({show, close}) => {


    const dispatch = useDispatch()


    const handleSignOut = () => {
        signOut(auth).then(() => {
          close()
          dispatch(setAuthenticated(false))
          
        }).catch((err) => {
    
        })
      }


  return (
    <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
      >
        <div style={{ margin: 20 }}>
          <div style={{ textAlign: 'center' }}>
            <img src={logo} style={{ width: '60%', marginBottom: 40 }} />
          </div>
          <h4 style={{ textAlign: 'center', marginBottom: 30 }}>Are You Sure You Want To Sign Out?</h4>

          <Button  style={{ width: '45%', marginRight: '2.5%', backgroundColor: 'black', borderColor: 'black' }} onClick={() => close()}>CANCEL</Button>
          <Button  style={{ width: '45%', marginLeft: '2.5%', backgroundColor: 'red', borderColor: 'red'  }} onClick={handleSignOut}>SIGN OUT</Button>
        </div>

      </Modal>  
  )
}

export default SignoutModal
