import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Colors } from '../commons/Colors';
import { setSelectedPart, getCategoryParts, selectedCategoryParts } from '../store/actions';
import { useSelector } from 'react-redux';
import { Container, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import 'intl'


const Category = () => {



  const catParts = useSelector(state => state.selectedCatParts)
  const category = useSelector(state => state.selectedCategory)
  const parts = useSelector(state => state.parts)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(selectedCategoryParts(parts, category.value));
  }, [])

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  if (catParts.length === 0) {
    return (

      <div>
        <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
          <h1 style={{ fontWeight: 'bold', }}>{category.name}</h1>
          <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
        </div>

        <div>
          <h1 style={{ textAlign: 'center', marginTop: 100, fontWeight: 'bold', color: 'blue' }}>NO {category.name} Available</h1>
          <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'blue' }}>Please come back later</p>
        </div>
      </div>

    )
  }




  return (
    <>
      <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
        <h1 style={{ fontWeight: 'bold', }}>{category.name}</h1>
        <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
      </div>


      <Container>
        <Row style={{ margin: '30px 0' }}>
          {catParts.map(part =>
            <Col md={3}>
              <a href='/part-details' onClick={()=>dispatch(setSelectedPart(part))} style={{textDecoration:'none', color:'black'}}>
                <div className='shadow' style={{ width: '100%', height: 300, borderRadius: 10, backgroundImage: `url(${part.data.images[0]})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>

                </div>
                <h4 style={{ color: 'black', fontWeight: 'bold', marginTop: 10 }}>{part.data.partName}</h4>
                <p style={{ textTransform: 'capitalize', margin: 0 }}>{part.data.town}, {part.data.parish}</p>
                <p>Sold By: <b>{part.data.soldBy}</b></p>
                <p style={{ fontWeight: 'bold', fontSize: '1.3em', marginTop: -20 }}>{formatter.format(part.data.price)}</p>
              </a>
            </Col>
          )
          }
        </Row>
      </Container>
    </>
  )
}


const styles = {
  card: {
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: 200,
    marginBottom: 20,
    cursor: 'pointer'
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,.7)',
  },
  heading: {
    color: '#fff',
    fontSize: 20,
    fontWeight: '600',
    textAlign: 'center'
  },
  price: {
    color: '#fff',
    fontSize: 20,
    fontWeight: '600',
    position: 'absolute',
    bottom: 10,
  },
  category: {
    color: '#fff',
    margin: 0,
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: '700',
    textAlign: 'center',
  }
}


export default Category;
