import { useEffect, useState } from 'react'
import { Upload, Space, message, Input, Select, InputNumber } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form, Container } from 'react-bootstrap';
import { InboxOutlined } from '@ant-design/icons';
import firebase, { firestore, storage, auth } from '../utils/firebase';
import { connect, useSelector } from 'react-redux';
import { Colors } from '../commons/Colors';
import { categories } from '../commons/categories';
import { parishes } from '../commons/parishes';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, addDoc, doc, updateDoc, onSnapshot, deleteDoc, Timestamp, } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;



const PostPart = (props) => {

    const [category, setCategory] = useState('')
    const [description, setDescription] = useState('')
    const [images, setImages] = useState([])
    const [name, setName] = useState('')
    const [price, setPrize] = useState(0)
    const [fileList, setFileList] = useState([]);
    const [town, setTown] = useState('')
    const [parish, setParish] = useState('')

    const user = useSelector(state => state.user)

    const isAuthenticated = useSelector(s => s.isAuthenticated)

    let navigate = useNavigate();


    if (!isAuthenticated) {
        navigate('/')
    }


    const createPost = () => {
        if (images.length > 0) {
            addDoc(collection(firestore, 'parts'),
                {
                    category: category,
                    dateAdded: new Date(),
                    description: description,
                    images: images,
                    partName: name,
                    email:user.data.email,
                    price: price,
                    userId: auth.currentUser.uid,
                    featured: false,
                    phone: user.data.phone,
                    town: town,
                    parish: parish,
                    soldBy: user.data.company,
                }
            ).then(() => {
                message.success(`Your Part was uploaded successfully.`);
                navigate('/my-parts')
            }).catch(error => {
                message.error(`There was an error while posting your part`);
            })
        } else {
            message.error(`Please add images before uploading`);
        }
    }

    const onPreview = async file => {
        // let src = file.url;
        // console.log('object', file)
        // if (!src) {
        //     src = await new Promise(resolve => {
        //         const reader = new FileReader();
        //         reader.readAsDataURL(file.originFileObj);
        //         reader.onload = () => resolve(reader.result);
        //     });
        // }
        // const image = new Image();
        // image.src = src;
        // const imgWindow = window.open(src);
        // imgWindow.document.write(image.outerHTML);
    };


    const handleUpload = async ({ onError, onSuccess, file }) => {

        const imageRef = ref(storage, `Parts/${file.name}`)

        uploadBytes(imageRef, file).then(() => {
            getDownloadURL(imageRef)
                .then((url) => {
                    images.push(url);
                    setImages(images)
                })
            console.log('Image was uploaded successfully',);
        });
    }

    const removeImage = async (e) => {

        const imageRef = ref(storage, `Parts/${this.state.file.name}`);
        deleteObject(imageRef).then(() => {
            message.success('Image was removed successfully')
        })

    }


    const fileProps = {
        name: 'file',
        multiple: true,
        accept: ".jpg, .jpeg, .png",
        maxCount: 10,
        onRemove: removeImage,
        listType: "picture-card",
        fileList: fileList,
        onPreview: onPreview,
        customRequest: handleUpload,
        onChange(info) {

            // const { status } = info.file;
            // setFileList(info.fileList);

            // if (status !== 'uploading') {
            //     console.log(info.file, info.fileList);
            // }
            // if (status === 'done') {
            //     message.success(`${info.file.name} file uploaded successfully.`);

            // } else if (status === 'error') {
            //     message.error(`${info.file.name} file upload failed.`);
            // }

        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };




    return (<>
        <div style={{ textAlign: 'center', backgroundColor: 'black', color: '#fff', padding: '40px 0' }}>
            <h1 style={{ fontWeight: 'bold', }}>Sell a Part</h1>
            <h6 style={{ fontWeight: 'bold', }}>Connecting people to parts anywhere</h6>
        </div>
        <div style={{ padding: 30 }}>

            <Container>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>


                        <Row>
                            <Col md={12}>
                                <label>Part Name<sup>*</sup></label>
                                <Form.Control
                                    type="text"
                                    id="inputEmailAddress"
                                    value={name} onChange={e => setName(e.target.value)} />
                            </Col>
                            <Col md={6}>
                                <label>Part Price<sup>*</sup></label>
                                <Form.Control
                                    type="number"
                                    id="inputEmailAddress"
                                    value={price} onChange={e => setPrize(e.target.value)} />
                                {/* <InputNumber min={0} value={price} defaultValue={1000} onChange={(value) => setPrize(value)} style={{ width: '100%' }} /> */}
                            </Col>
                            <Col md={6}>
                                <label>Part Category<sup>*</sup></label>
                                <Form.Select aria-label="Catetory Select" onChange={value => { setCategory(value.target.value); }}>
                                    <option>Select Part Category</option>
                                    {categories.map((cat) =>
                                        <option value={cat.value}>{cat.name}</option>
                                    )}
                                </Form.Select>

                            </Col>

                            <Col md={6}>
                                <label>Town<sup>*</sup></label>
                                <Form.Control
                                    type="text"
                                    id="inputEmailAddress"
                                    value={town} onChange={e => setTown(e.target.value)} />
                                {/* <Input value={town} onChange={e => setTown(e.target.value)} /> */}
                            </Col>
                            <Col md={6}>
                                <label>Parish<sup>*</sup></label>
                                <Form.Select aria-label="Parish Select" onChange={value => { setParish(value.target.value); }}>
                                    <option>Select your parish</option>
                                    {parishes.map((par) =>
                                        <option value={par.value}>{par.label}</option>
                                    )}
                                </Form.Select>
                            </Col>
                            <Col md={12}>
                                <label>Description <sup>*</sup></label>
                                <Form.Control as="textarea" rows={5} onChange={e => setDescription(e.target.value)} placeholder="Enter the the descriptionn for the part" />
                                {/* <TextArea rows={4} placeholder="Enter the the descriptionn for the part" maxLength={500} onChange={e => setDescription(e.target.value)} /> */}
                                <span style={{ fontSize: 12, color: Colors.oliveGreen }}>{500 - description.length} Characters remaining</span>
                            </Col>
                            {/* <Col md={12}>
                                <div style={{ position: 'relative' }}>
                                    <Dragger {...fileProps} style={{ marginTop: 15, marginBottom: 1 }}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Supports single or bulk upload for maximum of 10 Images. Strictly prohibit from uploading company data or other
                                            band files
                                        </p>
                                    </Dragger>
                                    <span style={{ fontSize: 12, color: Colors.oliveGreen }}>{10 - images.length} Images remaining</span>
                                </div>
                            </Col> */}



                            <Row>

                                <Col md={12}>
                                    {
                                        images.length < 10 &&
                                        <div style={{ position: 'relative' }}>
                                            <Dragger {...fileProps} style={{ marginTop: 15, marginBottom: 1 }}>
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text" style={{ color: '#fff' }}>Click or drag file to this area to upload</p>
                                                <p tyle={{ color: '#fff' }}>
                                                    Supports single or bulk upload for maximum of 10 Images. Strictly prohibit from uploading company data or other
                                                    band files
                                                </p>
                                            </Dragger>
                                            <span style={{ fontSize: 12, color: 'black', fontWeight: '700' }}>{images.length} Image(s) uploaded</span><br></br>
                                            <span style={{ fontSize: 12, color: 'black', fontWeight: '700' }}>{10 - images.length} Images remaining</span>
                                        </div>
                                    }


                                </Col>



                            </Row>

                            <Col md={12}>
                                <Button
                                    style={styles.button}
                                    onClick={createPost}
                                    disabled={
                                        name === '' ||
                                        category === '' ||
                                        price <= 0 ||
                                        town === '' ||
                                        parish === '' ||
                                        description === ''
                                    }
                                >
                                    POST THIS PART
                                </Button>
                            </Col>

                        </Row>
                    </Col>
                    <Col md={2}></Col>

                </Row>

            </Container>
        </div>
    </>)


}

const styles = {
    button: {
        width: '100%',
        marginTop: 30,
        fontWeight: '700',
        backgroundColor: 'black',
        color: 'white',
        borderColor: 'black',
    }
}

const mapStateToProps = (state) => {
    return { user: state.user }
}

export default connect(mapStateToProps, {})(PostPart);